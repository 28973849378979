<template>
  <div class="project-body">
    <Navbar />
    <div class="project">
      <h1>Kuizu</h1>
      <p>
        Projet réalisé à l'aide de react native (en cours de création)
      </p>
    </div>

    <div class="container-project">
      <img
        class="imgSpeciale"
        src="@/assets/images/portfolio/kuizu/kuizu-12.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
      <p>
        Il s'agit d'une application pour s'entrainer à apprendre le japonais.
        <br /><br />
        L'utilisateur pourra s'identifer via son compte facebook ou jouer sans
        identification.
      </p>
    </div>
    <div class="container-project1">
      <p>
        Le but est de participer à des jeux sous forme de quiz (kuizu)<br /><br />
        Il y aura les incontournables (hiragana, katakana et kanji)<br /><br />
        Ensuite, je vais ajouter des phrases à trous.
      </p>
      <img
        class="second"
        src="@/assets/images/portfolio/kuizu/kuizu-2.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>
    <p>
      <router-link
        class="btn btn-dark mt-2 float-lg-center mb-lg-5 btn-sm"
        :to="{ name: 'Portfolio' }"
      >
        <fontAwesomeIcon :icon="['fas', 'arrow-left']" class=" menuColor" />
        Retour vers le portfolio
      </router-link>
    </p>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Lettresmissions",
  components: { Navbar, Footer }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/projetView.scss";
</style>
